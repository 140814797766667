<template>
  <div id="app">
    <section style="background-color: #F5F5F9;min-height: 100%" :class="{pc: isMobile}">
      <router-view/>
    </section>
  </div>
</template>
<script>
  function isMobile() {
    return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
  }
  let aa = isMobile();
  export default {
    name: "App",

    data() {

      return {
        isMobile: !aa
      }
    }
  }
</script>
<style lang="less">
  * {
    margin: 0;
    padding: 0;
  }
  html,body, #app{ width: 100%; height: 100%; overflow: auto;background-color: #F5F5F9;}
  html::-webkit-scrollbar, body::-webkit-scrollbar{width:0;height:0;}
  body{margin:0;}
  .pc {
    margin: 0 auto;
    max-width: 500px;
  }
</style>
