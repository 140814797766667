import axios from "axios";
import router from "../router"

// let baseUrl = "http://192.168.124.61:19309"
// let baseUrl ="https://museum-voice.bowu66.com"
let baseUrl = "http://47.114.36.134:19309"

export let network = function (url, data = {}, method = 'get') {
    return axios({
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            signature: localStorage.getItem('signature'),
            token:localStorage.getItem('userId'),
            appid:'ZSKJ_js8rwachLPIF5PtsFnl'
          },
        url: baseUrl + url,
        method,
        params: method === 'get' ? data : {},
        data: JSON.stringify(data)
      
    })
    
};

axios.interceptors.response.use(res=>{
    console.log(res.data.code)
    if(res.data.code == 4001) {
        localStorage.removeItem("signature")
        // router.replace({path: "/Login"})
    }
    return res;
})
